import React from "react";
import Seo from "../components/seo";

const CoolStuff = () => {
    return (
        <div>
            <Seo title="Clayton Davidson"
                 description="Browse cool stuff that I've come across while surfing the Internet"/>
            <h1 style={{color: "ghostwhite"}}>Reserved for Cool Stuff</h1>
        </div>
    );
}

export default CoolStuff;
